<template>
  <div>
    <v-card elevation="1">
      <v-card-actions class="py-1">
        <v-spacer></v-spacer>
      </v-card-actions>
      <v-data-table
        :headers="headers"
        :items="smsHttpTrunks"
        :server-items-length="totalSmsHttpTrunks"
        :options.sync="options"
      >
        <template v-slot:[`item.indexNo`]="{ item }">
          {{
            (options.page - 1) * options.itemsPerPage +
            (smsHttpTrunks.indexOf(item) + 1)
          }}
        </template>
      </v-data-table>
      <loading :active="isLoading" :loader="loader" />
    </v-card>
  </div>
</template>

<script>
import profileAPI from "@/services/profileAPI.js";
import moment from "moment";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  components: {
    Loading
  },
  data() {
    return {
      isLoading: false,
      loader: "bars",
      options: {
        page: 1,
        itemsPerPage: 10
      },
      totalSmsHttpTrunks: 0,
      headers: [
        { text: "#", value: "indexNo", width: "5%" },
        { text: "SMS Time", value: "smsTime", width: "15%", align: "center", sortable: true },
        { text: "Src Addr.", value: "smsSrcAddr", width: "15%", align: "center", sortable: true },
        { text: "DID Number", value: "smsDstAddr", width: "15%", align: "center", sortable: true },
        { text: "SMS Text", value: "smsText", align: "center", sortable: true }
      ],
      smsHttpTrunks: []
    };
  },
  watch: {
    options: {
      handler() {
        this.getSMSTrunks();
      },
      deep: true
    }
  },
  mounted() {
    this.userDetail = JSON.parse(localStorage.getItem("userDetail"));
  },
  methods: {
    async getSMSTrunks() {
      this.isLoading = true;
      const { page, itemsPerPage } = this.options;
       var payload = {
        viewIndex: page,
        viewSize: itemsPerPage,
        partyId: this.userDetail.partyId,
      };

      try {
        const response = await profileAPI.getSMSTrunksApi(payload);
        console.log('API Response:', response); // Debugging line to check the entire response object

        // Accessing the correct properties based on the provided response structure
        if (response) {
          this.totalSmsHttpTrunks = response.listSize || 0;
          this.smsHttpTrunks = response.smsHttpTrunks || [];
          console.log('SMS HTTP Trunks:', this.smsHttpTrunks); // Debugging line to check the data
        } else {
          console.error('Unexpected response structure:', response.data);
        }
      } catch (error) {
        console.error("Error fetching SMS trunks:", error);
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>

<style scoped>
.text-capitalize {
  text-transform: capitalize;
}
</style>
